<template>
    <StationViewLoading v-if="$store.state.station.loading.loading"/>
    <StationView v-else/>
</template>

<script>
import {store} from "../store/store.js";
import StationView from "../components/StationView.vue";
import StationViewLoading from "../components/StationViewLoading.vue";

export default {
    name: "RouterStationView",
    components: {StationView, StationViewLoading},
    beforeRouteEnter(to, from, next) {
        next();
        store.dispatch("station/fetch", to.params.stationId);
        store.dispatch("availableCommands/fetch");
    }
}
</script>
