<template>
    <div v-click-outside="hide" class="relative w-max">
        <button
            @click="toggleMenu"
            class="flex items-center gap-2 w-56 px-4 py-2 rounded-full shadow-sm
            bg-white hover:bg-gray-50 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
        >
            <div v-if="modelValue">{{modelValue.text}}</div>
            <div v-else>{{defaultMessage}}</div>
            <div class="ml-auto">
                <icon type="mdi" class="transform" :path="icons.mdiChevronDown"/>
            </div>
        </button>
        <div
            class="content absolute top-full rounded shadow-md mt-2 z-10
                   left-0 right-0 transition-all transform origin-top-right scale-0 opacity-0
                 bg-white dark:bg-gray-700 dark:text-white"
            :class="{open: menuOpen}"
        >
            <ul class="max-h-48 py-2 overflow-y-auto">
                <li
                    class="block w-full"
                    v-for="option in options" :key="option"
                >
                    <button
                        @click="selectOption(option)"
                        class="block px-2 py-1 w-full text-left hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors"
                    >{{option.text}}</button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import * as icons from "@mdi/js";

export default {
    name: "Dropdown",
    props: {
        options: {
            type: Array,
            required: true
        },
        defaultMessage: {
            type: String,
            required: false,
            default: "Select option"
        },
        modelValue: {
            type: Object
        }
    },
    emits: ["update:modelValue", "update"],
    data() {
        return {
            menuOpen: false,
            icons
        };
    },
    methods: {
        hide() {
            this.menuOpen = false;
        },
        toggleMenu() {
            this.menuOpen = !this.menuOpen;
        },
        selectOption(option) {
            this.$emit("update", option)
            this.$emit("update:modelValue", option)
            this.menuOpen = false;
        }
    }
}
</script>

<style scoped>
.content.open {
    @apply scale-100 opacity-100;
}
</style>
