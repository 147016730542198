<template>
    <div>
        <h2 class="text-2xl font-bold mb-6">Zones</h2>    
        <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
            <GroupsViewItem
                v-for="group in $store.state.groups.groups"
                :key="group.id"
                :id="group.id"
                :title="group.name"
                :stationsCount="group.stations.length"
            />
        </div>
    </div>
</template>

<script>
import GroupsViewItem from "./GroupsViewItem.vue";

export default  {
    components: { GroupsViewItem },
    name: "GroupsView"
}
</script>
