<template>
    <div :class='{"dark:bg-gray-800 bg-white p-6 rounded-md shadow-md": !small}'>
        <div class="flex flex-row-reverse flex-wrap items-start justify-end">
            <div class="tab-select flex ml-auto rounded-full w-max gap-2">
                <button
                    @click="selectTab('equipments')"
                    :class='{"selected": tab == "equipments"}'
                    class="flex gap-2 items-center"
                >
                    <span>Equipements</span>
                    <icon type="mdi" :path="icons.mdiViewDashboard"/>
                </button>
                <button
                    @click="selectTab('commands')"
                    :class='{"selected": tab == "commands"}'
                    class="flex gap-2 items-center"
                >
                    <span>Commandes</span>
                    <icon class="text-2xl" type="mdi" :path="icons.mdiAccessPoint"/>
                </button>
                <button
                    @click="selectTab('events')"
                    :class='{"selected": tab == "events"}'
                    class="flex gap-2 items-center"
                >
                    <span>Evénements</span>
                    <icon type="mdi" :path="icons.mdiAlert"/>
                </button>
            </div>
            <h2 class="text-2xl mb-4 font-bold">{{station.mqttTopic}} - {{station.name}}</h2>
        </div>
        <div>
            <h2 class="py-2 my-4 text-lg font-bold border-b border-gray-200 dark:border-gray-700">Infos</h2>
            <div class="flex gap-4 p-2 items-start">
                <div class="w-6 h-6 rounded-full" :class="{
                    'bg-white border-2 border-gray-500': online && !stationStorm && !stationHighWind,
                    'bg-yellow-500': online && stationHighWind,
                    'bg-red-500': online && stationStorm,
                    'bg-gray-500': !online
                }"></div>
                <div class="flex-1">
                    <p>Statut :
                        <template v-if="online && !stationStorm && !stationHighWind">Feu éteint</template>
                        <template v-else-if="online && stationStorm">Tempête</template>
                        <template v-else-if="online && stationHighWind">Vent fort</template>
                        <template v-else>Indisponible</template>
                    </p>
                    <p>Dernière mise à jour: {{station.lastUpdate ? formatedDate(station.lastUpdate) : "-"}}</p>
                </div>
            </div>
            <div class="flex gap-4 p-2 items-start">
                <icon type="mdi" :path="icons.mdiCardText"/>
                <p class="flex-1">{{station.name}}</p>
            </div>
            <div class="flex gap-4 p-2 items-start">
                <icon type="mdi" :path="icons.mdiCardTextOutline"/>
                <p class="flex-1">{{station.description}}</p>
            </div>
            <router-link
                :to="{name: 'Map', query: {station: station.id}}"
                class="w-max transition-colors flex gap-4 p-2 items-center hover:text-blue-900 hover:bg-blue-100 dark:hover:bg-gray-700 dark:hover:text-white rounded-md"
            >
                <icon type="mdi" :path="icons.mdiCrosshairsGps"/>
                <div class="">
                    <div>Lat : {{station.gpslat}}°</div>
                    <div>Lon : {{station.gpslong}}°</div>
                </div>
            </router-link>
        </div>
        <div v-show="tab == 'equipments'">
            <h3 class="py-2 my-4 text-lg font-bold border-b border-gray-200 dark:border-gray-700">Equipements</h3>
            <div
                class="grid grid-cols-1 gap-4"
                :class="{'md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4': !small}"
            >
                <StationViewEquipmentItem
                    v-for="equipmentInstallation in station.equipmentInstallations"
                    :key="`${equipmentInstallation.mqttTopic}_${equipmentInstallation.equipment.id}`"
                    :topic="equipmentInstallation.mqttTopic"
                    :equipment="equipmentInstallation.equipment"
                    :sensorData="equipmentInstallation.sensorData"
                />
            </div>
        </div>
        <div v-show="tab == 'commands'">
            <h3 class="py-2 my-4 text-lg font-bold border-b border-gray-200 dark:border-gray-700">Commandes</h3>
            <div class="rounded-t-md overflow-hidden overflow-x-auto">
                <div v-if="!$store.state.station.loadingCommands" class="commands-table rounded-md table table-auto w-full">
                    <div class="table-row font-bold bg-blue-100 dark:bg-gray-700 dark:text-white text-blue-900">
                        <div class="table-cell p-2">Equipement</div>
                        <div class="table-cell p-2">Commande</div>
                        <div class="table-cell p-2">Valeur</div>
                        <div class="table-cell p-2">Début</div>
                        <div class="table-cell p-2">Fin</div>
                        <div class="table-cell p-2">Temps restant</div>
                    </div>
                    <div v-for="command in $store.state.station.commands" :key="command.id" class="table-row">
                        <div class="table-cell p-2">{{command.topic || "-" }}</div>
                        <div class="table-cell p-2">{{commandName(command.topic, command.command)}}</div>
                        <div class="table-cell p-2">{{valueName(command.topic, command.command, command.value)}}</div>
                        <div class="table-cell p-2">{{formatedDate(command.startDate)}}</div>
                        <template v-if="!command.endDate">
                            <div class="table-cell p-2">-</div>
                            <div class="table-cell p-2">-</div>
                        </template>
                        <template v-else>
                            <div class="table-cell p-2">{{formatedDate(command.endDate)}}</div>
                            <div class="table-cell p-2">{{remainingTime(command.endDate)}}</div>
                        </template>
                    </div>
                </div>
            </div>
            <router-link
                v-if="$store.getters.session.isOperator"
                :to='{name: "Commands", params: {groupId: station.stationGroupId}, query: {preselectAllStations: "false", preselectedStations: station.id}}'
                class="mt-4 flex items-center rounded-full px-4 py-2 transition-colors w-max
                     bg-blue-500 hover:bg-blue-600 text-white
                     dark:bg-gray-700 dark:hover:bg-gray-600"
            >
                <icon type="mdi" :path="icons.mdiAccessPoint"/>
                <icon type="mdi" :path="icons.mdiPlus"/>
            </router-link>
        </div>
        <div v-show="tab == 'events'">
            <h3 class="py-2 my-4 text-lg font-bold border-b border-gray-200 dark:border-gray-700 space-y-4">Alarmes</h3>
            <div v-if="!$store.state.station.eventsLoading.loading" class="events-table overflow-x-auto table table-auto w-full">
                <div class="table-row font-bold">
                    <div class="table-cell"></div>
                    <div class="table-cell"></div>
                    <div class="table-cell">Equipement</div>
                    <div class="table-cell">Message</div>
                    <div class="table-cell">Valeur</div>
                    <div class="table-cell">Date</div>
                </div>
                <div
                    v-for="event in $store.state.station.events"
                    :key="event.id"
                    class="table-row rounded-lg"
                >
                    <div class="table-cell w-1 px-2 rounded-l-lg" :class="eventColorClass(event.category, event.seen)">
                        <div v-if="$store.getters.session.isMaintenance">
                            <button @click="setEventSeen(event.id, !event.seen)">
                                <icon v-if="event.seen" type="mdi" :path="icons.mdiClipboardCheck"/>
                                <icon v-else type="mdi" :path="icons.mdiClipboardAlert"/>
                            </button>
                        </div>
                        <div v-else>
                            <icon v-if="event.seen" type="mdi" :path="icons.mdiClipboardCheck"/>
                            <icon v-else type="mdi" :path="icons.mdiClipboardAlert"/>
                        </div>
                    </div>
                    <div class="table-cell w-1 px-2" :class="eventColorClass(event.category, event.seen)">
                        <icon v-if="event.category == 1" type="mdi" :path="icons.mdiAlert"/>
                        <icon v-else-if="event.category == 2" type="mdi" :path="icons.mdiAlertOctagram"/>
                        <icon v-else type="mdi" :path="icons.mdiInformation"/>
                    </div>
                    <div class="table-cell" :class="eventColorClass(event.category, event.seen)">{{event.topic}}</div>
                    <div class="table-cell" :class="eventColorClass(event.category, event.seen)">{{event.eventMessage.message}}</div>
                    <div class="table-cell" :class="eventColorClass(event.category, event.seen)">{{event.value}}</div>
                    <div class="table-cell rounded-r-lg" :class="eventColorClass(event.category, event.seen)">{{formatedDate(event.eventDate)}}</div>
                </div>
            </div>
            <div class="flex items-center mt-4">
                <div class="flex-1">Affichage de {{$store.state.station.events.length}} des {{$store.state.station.eventsCount}} événements</div>
                <div class="flex gap-2 items-center">
                    <button v-if="$store.state.station.eventsPage > 1" @click="fetchPrevEventsPage" class="p-2 border border-gray-700 rounded-md">précédent</button>
                    <button v-if="hasNextPage" @click="fetchNextEventsPage" class="p-2 border border-gray-700 rounded-md">suivant</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StationViewLoading from "./StationViewLoading.vue";
import StationViewEquipmentItem from "./StationViewEquipmentItem.vue";
import * as icons from "@mdi/js";

export default {
    name: "StationView",
    components: { StationViewLoading, StationViewEquipmentItem },
    data() {
        return {
            icons,
            tab: this.$route.hash.replace("#",'') || "equipments",
            updateInterval: 0
        }
    },
    mounted() {
        this.updateInterval = setInterval(() => {
            this.$store.dispatch("station/fetch", this.station.id);
        }, 15000)
    },
    unmounted() {
        clearInterval(this.updateInterval)
    },
    props: {
        small: {
            type: Boolean,
            required: false,
            defaultValue: false
        }
    },
    computed: {
        online() {
            return (new Date(this.station.lastUpdate).getTime() > Date.now() - 1800000); // 30min
        },
        station() {
            return this.$store.state.station.station;
        },
        stationStorm() {
            return (this.station.equipmentInstallations || [])
                .filter((ei) => ei.equipment.equipmentIdentifier == 2)
                .flatMap((ei) => ei.sensorData.filter((sd) => sd.type == "1"))
                .map((sd) => sd.value)
                .some((v) => v == 90)
        },
        stationHighWind() {
            return (this.station.equipmentInstallations || [])
                .filter((ei) => ei.equipment.equipmentIdentifier == 2)
                .flatMap((ei) => ei.sensorData.filter((sd) => sd.type == "1"))
                .map((sd) => sd.value)
                .some((v) => v == 40)
        },
        hasNextPage() {
            const page = this.$store.state.station.eventsPage;
            const eventsCount = this.$store.state.station.eventsCount;
            const eventsPageSize = this.$store.state.station.eventsPageSize;
            const eventsInPage = this.$store.state.station.events.length;
            return ((page - 1) * eventsPageSize + eventsInPage) < eventsCount;
        }
    },
    methods: {
        eventColorClass(category, seen) {
            return  {
                '': seen,
                'bg-green-200 dark:bg-green-300 text-green-900': category == 0 && !seen,
                'bg-yellow-200 dark:bg-yellow-300 text-yellow-900': category == 1 && !seen,
                'bg-red-200 dark:bg-red-300 text-red-900': category == 2 && !seen,
            };
        },
        fetchPrevEventsPage() {
            this.$store.dispatch("station/fetchEvents", {stationId: this.station.id, page: this.$store.state.station.eventsPage - 1});
        },
        fetchNextEventsPage() {
            this.$store.dispatch("station/fetchEvents", {stationId: this.station.id, page: this.$store.state.station.eventsPage + 1});
        },
        fetchCurrentEventsPage() {
            this.$store.dispatch("station/fetchEvents", {stationId: this.station.id, page: this.$store.state.station.eventsPage});
        },
        async setEventSeen(eventId, seen) {
            console.log("event seen")
            await this.$store.dispatch("station/setEventSeen", {eventId, seen});
            this.fetchCurrentEventsPage()
        },
        formatedDate(value) {
            const date = new Date(value);
            const locale = "fr-CH";
            return `${date.toLocaleDateString(locale)} ${date.toLocaleTimeString(locale)}`;
        },
        selectTab(tab) {
            this.tab = tab;
            history.pushState(null, null, "#" + tab);
        },
        remainingTime(endDate) {
            let diffTime = (new Date(endDate).getTime() - Date.now()) / 1000;

            const hours = Math.floor(diffTime / 3600).toString().padStart(2,"0"); // 1h
            diffTime -= hours * 3600;

            const minutes = Math.floor(diffTime / 60).toString().padStart(2,"0"); // 1min
            diffTime -= minutes * 60;

            const seconds = Math.floor(diffTime).toString().padStart(2,"0");
            
            return `${hours}:${minutes}:${seconds}`;
        },
        commandName(equipmentTopic, command) {
            const equipmentIdentifier = this.station.equipmentInstallations
                .filter(ei => ei.mqttTopic == equipmentTopic)
                .pop().equipment.equipmentIdentifier;

            const matchCommand = this.$store.state.availableCommands.commands
                .filter(c => c.equipmentIdentifier == equipmentIdentifier && c.command == command)
                .pop();

            return matchCommand ? matchCommand.title : command;
        },
        valueName(equipmentTopic, command, value) {
            const equipmentIdentifier = this.station.equipmentInstallations
                .filter(ei => ei.mqttTopic == equipmentTopic)
                .pop().equipment.equipmentIdentifier;

            const matchCommand = this.$store.state.availableCommands.commands
                .filter(c => c.equipmentIdentifier == equipmentIdentifier && c.command == command)
                .pop();

            if(!matchCommand || !matchCommand.availableCommandOptions.length) return value;

            const matchValue = matchCommand.availableCommandOptions
                .filter(o => o.value == value)
                .pop();
            
            return matchValue ? matchValue.title : value;
        }
    }
}
</script>

<style scoped>

.events-table {
    border-collapse: separate;
    border-spacing: 0 8px;
}

.events-table .table-row .table-cell {
    vertical-align: middle;
    @apply p-2;
}

.tab-select button {
    @apply transition-colors p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700;
}

.tab-select button.selected {
    @apply bg-blue-100 hover:bg-blue-100 text-blue-900 dark:bg-gray-600 dark:text-white;
}
</style>
