export const clickOutsideDirective = {
    mounted(el, binding, vnode, prevNode) {
        vnode.clickOutsideEvent = (e) => {
            if(!(el === e.target || el.contains(e.target))) {
                binding.value(e);
            }
        };
        document.body.addEventListener("click", vnode.clickOutsideEvent);
    },
    unmounted(el, binding, vnode, prevNode) {
        document.body.removeEventListener("click", vnode.clickOutsideEvent);
    }
}
