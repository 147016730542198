<template>
    <div class="loading p-4">
        <div class="flex items-center gap-8 mb-16">
            <div class="loading-item rounded h-6 w-96"></div>
            <div class="flex ml-auto gap-2">
                <div class="loading-item w-8 h-8 rounded-full"></div>
                <div class="loading-item w-8 h-8 rounded-full"></div>
                <div class="loading-item w-8 h-8 rounded-full"></div>
            </div>
        </div>
        <div class="loading-item h-4 w-64 rounded mb-8"></div>
        <div class="flex my-4 items-center gap-4">
            <div class="loading-item w-6 h-6 rounded-full"></div>
            <div class="loading-item h-3 w-64 rounded"></div>
        </div>
        <div class="flex my-4 items-center gap-4">
            <div class="loading-item w-6 h-6 rounded-full"></div>
            <div class="loading-item h-3 w-64 rounded"></div>
        </div>
        <div class="flex my-4 items-center gap-4">
            <div class="loading-item w-6 h-6 rounded-full"></div>
                <div class="loading-item h-3 w-64 rounded"></div>
            </div>
            <div class="loading-item h-4 w-64 rounded mt-16 mb-8"></div>
            <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
                <div class="loading-item h-64 w-full rounded-md"></div>
                <div class="loading-item h-64 w-full rounded-md"></div>
                <div class="loading-item h-64 w-full rounded-md"></div>
                <div class="loading-item h-64 w-full rounded-md"></div>
            </div>
        </div>
</template>

<script>
export default {
    name: "StationViewLoading"
}
</script>

<style scoped>
    .loading .loading-item {
        @apply bg-gray-400 dark:bg-gray-500 max-w-full;
    }

    .loading {
        @apply animate-pulse;
    }
</style>
