export default {
    namespaced: true,
    state() {
        return {
            loading: true
        }
    },
    mutations: {
        start(state) {
            state.loading = true;
        },
        stop(state) {
            state.loading = false;
        }
    },
    actions: {
        start(context) {
            context.commit("start");
        },
        stop(context) {
            context.commit("stop");
        }
    }
}
