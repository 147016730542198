<template>
    <div class="flex shadow-md p-2 rounded-full bg-white dark:bg-gray-800 items-center gap-1 overflow-x-auto">
        <router-link to="/groups" class="breadscrub-item">
            <icon type="mdi" :path="icons.mdiViewListOutline"/>
        </router-link>
        <template v-for="pathEl in $store.getters.breadscrubPath" :key="pathEl">
            <icon type="mdi" :path="icons.mdiChevronRight"/>
            <router-link :to="pathEl.link" class="breadscrub-item">
                {{pathEl.text}}
            </router-link>
        </template>
    </div>
</template>

<script>
import * as icons from "@mdi/js";

export default {
    name: "Breadscrub",
    data() {
        return { icons }
    }
}
</script>

<style scoped>
.breadscrub-item {
    @apply p-2 rounded-full bg-blue-100 text-blue-900 dark:text-white hover:bg-blue-200
           transition-colors dark:bg-gray-700 dark:hover:bg-gray-600 whitespace-nowrap;
}
</style>
