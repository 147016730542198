import { createApp } from 'vue'
import App from './App.vue'
import {clickOutsideDirective} from "./directives/clickoutside.js";
 
import SvgIcon from "@jamescoyle/vue-icon";
import router from "./router/index.js";
import {store} from "./store/store.js";

import "./main.css";

createApp(App)
    .use(router)
    .use(store)
    .directive("click-outside", clickOutsideDirective)
    .component("icon", SvgIcon)
    .mount('#app')
