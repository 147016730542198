<template>
    <router-link
        :to="{name: 'Station', params: {stationId: id}}"
        class="bg-white dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 p-6 rounded-md shadow-md"
    >
        <div class="flex items-center gap-4 mb-2">
            <!--<div v-if="status == 'online'" class="bg-green-500 rounded-full h-5 w-5"></div>-->
            <!--<div v-if="status == 'offline'" class="bg-gray-500 rounded-full h-5 w-5"></div>-->
            <div class="text-xl font-bold ">
                {{mqttTopic}}
            </div>
        </div>
        <div>
            <div class="py-2 text-lg font-bold">
                Infos
            </div>
            <div class="flex gap-4 p-2 items-start">
                <icon type="mdi" :path="icons.mdiCardText"/>
                <p class="flex-1">{{name}}</p>
            </div>
            <div class="flex gap-4 p-2 items-start">
                <icon type="mdi" :path="icons.mdiCardTextOutline"/>
                <p class="flex-1">{{description}}</p>
            </div>
        </div>
    </router-link>
</template>

<script>
import * as icons from "@mdi/js"

export default {
    name: "StationsViewItem",
    props: {
        id: {
            type: String,
            required: true
        },
        status: {
            type: String,
            required: true
        },
        mqttTopic: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            icons
        }
    }
}
</script>
