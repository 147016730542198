<template>
    <MapView/>
</template>

<script>
import MapView from "../components/MapView.vue";

export default {
    components: { MapView },
    name: "RouterMapView"
}
</script>
