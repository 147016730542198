import { ApiDataSource } from "../../apollo/apiDataSource";
import loadingModule from "./loading.module.js";

export default {
    namespaced: true,
    state() {
        return {
            group: null
        }
    },
    mutations: {
        setGroup(state, group) {
            state.group = group;
        }
    },
    actions: {
        async fetch(context, groupId) {
            context.dispatch("loading/start");
            await ApiDataSource.fetchAccessTokenIfExpired();
            const group = await ApiDataSource.getGroup(groupId);
            context.commit("setGroup", group);
            context.dispatch("loading/stop");
        },
        async fetchWithInstallations(context, groupId) {
            context.dispatch("loading/start");
            await ApiDataSource.fetchAccessTokenIfExpired();
            const group = await ApiDataSource.getGroupWithInstallations(groupId);
            context.commit("setGroup", group);
            context.dispatch("loading/stop");
        }
    },
    modules: {
        loading: loadingModule
    }
}
