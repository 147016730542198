<template>
    <div>
        <div class="flex gap-2 items-top py-4">
            <button
                @click="openMenu"
                class="block lg:hidden rounded-full p-4 hover:bg-gray-100 dark:hover:bg-gray-700
                     bg-white dark:bg-gray-800 shadow-md"
            >
                <icon type="mdi" :path="mdiMenu"/>
            </button>
            <Breadscrub v-if="showBreadscrub"/>
        </div>
    </div>
</template>

<script>
import Breadscrub from "./Breadscrub.vue";
import {mdiMenu} from "@mdi/js";

export default {
    name: "HeaderBar",
    components: {Breadscrub},
    data() {
        return {
            mdiMenu
        }
    },
    computed: {
        showBreadscrub() {
            return ["Groups", "Group", "Station", "Commands"].includes(this.$route.name);
        }
    },
    methods: {
        openMenu() {
            if(!this.$store.state.menu.open)
            {
                // Prevent click outside from closing menu
                setTimeout(() => {
                    this.$store.dispatch("menu/open");
                }, 50);
            }
        }
    }
}
</script>
