<template>
    <div class="rounded-md bg-blue-50 text-blue-900 dark:text-white dark:bg-gray-700 p-2">
        <div class="flex gap-4 p-2 border-b border-blue-900 dark:border-gray-600 mb-2 items-center">
            <div class="text-xl">
                <icon type="mdi" :path="icon"/>
            </div>
            <p class="text-lg">{{topic}}</p>
        </div>
        <template v-for="(element, index) in content" :key="`${equipment.id}_${index}`">
            <div class="flex gap-4 p-2 items-start">
                <icon type="mdi" :path="element.icon"/>
                <div>
                    {{element.label}} : {{element.value}}
                    <template v-if="element.unit">
                        [{{element.unit}}]
                    </template>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import * as mdiIcons from "@mdi/js";

const WEATHER_EQUIPMENT_TYPE = 1;
const STORM_EQUIPMENT_TYPE = 2;

export default {
    name: "StationViewEquipmentItem",
    methods: {
        formatValue(value) {
            return Math.floor(value * 10.) / 10.;
        }
    },
    computed: {
        content() {
            return this.sensorData
		.filter((sd) => this.equipment.equipmentDatafields.filter((edf) => edf.type == sd.type).pop())
                .map((sd) => {
		    const datafield = this.equipment.equipmentDatafields.filter((edf) => edf.type == sd.type).pop();
                    return {
                        label: datafield.label,
                        icon: mdiIcons[datafield.icon],
                        value: this.formatValue(sd.value),
                        unit: datafield.unit
                    };
                });
        },
        icon() {
            return mdiIcons[this.equipment.icon]
        }
    },
    props: {
        equipment: {
            required: true
        },
        topic: {
            required: true,
            type: String
        },
        sensorData: {
            required: true
        }
    }
}
</script>
