export default {
    namespaced: true,
    state() {
        return {
            open: false
        }
    },
    mutations: {
        open(state) {
            state.open = true;
        },
        close(state) {
            state.open = false;
        }
    },
    actions: {
        open(context) {
            context.commit("open");
        },
        close(context) {
            context.commit("close");
        }
    }
}
