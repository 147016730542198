<template>
    <div>
        <div class="flex items-center mb-6">
            <h2 class="text-2xl font-bold">{{$store.state.group.group.name}} - Stations</h2>
            <router-link
                v-if="$store.getters.session.isOperator"
                :to='{name: "Commands", params: {groupId: $store.state.group.group.id}}'
                class="ml-auto flex rounded-full px-4 py-2
                     bg-blue-500 hover:bg-blue-600 text-white
                     dark:bg-gray-800 dark:hover:bg-gray-700">
                <icon type="mdi" :path="icons.mdiAccessPoint"/>
                <icon type="mdi" :path="icons.mdiPlus"/>
            </router-link>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
            <StationsViewItem
                v-for="station in $store.state.group.group.stations"
                :id="station.id"
                :key="station.id"
                :status="stationOnline(station) ? 'online' : 'offline'"
                :mqttTopic="station.mqttTopic"
                :name="station.name"
                :description="station.description"
            />
        </div>
    </div>
</template>

<script>
import StationsViewLoading from "./StationsViewLoading.vue";
import StationsViewItem from "./StationsViewItem.vue";
import * as icons from "@mdi/js";

export default {
    name: "StationsView",
    components: {
        StationsViewLoading,
        StationsViewItem
    },
    data() {
        return {icons};
    },
    methods: {
        stationOnline(station) {
            return (new Date(station.lastUpdate).getTime() > Date.now() - 1800000); // 30min
        },
        stationStorm(station) {
            return (station.equipmentInstallations || [])
                .filter((ei) => ei.equipment.equipmentIdentifier == 2)
                .flatMap((ei) => ei.sensorData.filter((sd) => sd.type == "1"))
                .map((sd) => sd.value)
                .some((v) => v == 90)
        },
        stationHighWind(station) {
            return (station.equipmentInstallations || [])
                .filter((ei) => ei.equipment.equipmentIdentifier == 2)
                .flatMap((ei) => ei.sensorData.filter((sd) => sd.type == "1"))
                .map((sd) => sd.value)
                .some((v) => v == 40)
        }
    }
}

</script>
