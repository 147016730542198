<template>
    <router-link v-if="link" :to="link" class="item" :class="{'selected': selected}">
        <icon type="mdi" :path="icon"/>
        <span>{{title}}</span>
    </router-link>
    <button v-else class="item" :class="{'selected': selected}">
        <icon type="mdi" :path="icon"/>
        <span>{{title}}</span>
    </button>
</template>

<script>

export default {
    name: "HeaderNavItem",
    props: {
        link: {
            type: String,
            required: false
        },
        title: {
            type: String,
            required: true
        },
        icon: {
            required: true
        },
        selected:  {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>

<style scoped>
.item {
    @apply flex p-4 pr-16 transition-colors items-center gap-4 relative rounded-r-full;
}

.item.selected {
    @apply bg-blue-100 text-blue-900 hover:bg-blue-200 dark:bg-gray-500 dark:text-white dark:hover:bg-gray-400;
}

.item:not(.selected) {
    @apply hover:bg-gray-100 dark:hover:bg-gray-600;
}
</style>
