<template>
    <GroupsViewLoading v-if="$store.state.groups.loading.loading"/>
    <GroupsView v-else/>
</template>

<script>
import {store} from "../store/store.js";

import GroupsView from "../components/GroupsView.vue";
import GroupsViewLoading from '../components/GroupsViewLoading.vue';

export default {
    name: "RouterGroupsView",
    components: {GroupsView, GroupsViewLoading},
    beforeRouteEnter(to, from, next) {
        next();
        store.dispatch("groups/fetch");
    }
}
</script>


