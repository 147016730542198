<template>
    <StationsViewLoading v-if="this.$store.state.group.loading.loading"/>
    <StationsView v-else/>
</template>

<script>
import {store} from "../store/store.js";

import StationsView from "../components/StationsView.vue";
import StationsViewLoading from "../components/StationsViewLoading.vue";

export default {
    name: "RouterStationView",
    components: {StationsView, StationsViewLoading},
    beforeRouteEnter(to, from, next) {
        next();
        store.dispatch("group/fetch", to.params.groupId);
    }
}
</script>
