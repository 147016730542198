import { createStore } from "vuex";
import { ApiDataSource } from "../apollo/apiDataSource";
import router from "../router/index.js";
import jwtDecode from "jwt-decode";

import groupsModule from "./modules/groups.module.js";
import groupModule from "./modules/group.module.js";
import stationModule from "./modules/station.module.js";
import availableCommandsModule from "./modules/available_commands.module.js";
import weatherWarningsModule from "./modules/weather_warnings.module.js";
import loggedUserModule from "./modules/logged_user.module.js";
import menuModule from "./modules/menu.module.js";
import userModule from "./modules/user.module.js";

// Create a new store instance.
export const store = createStore({
    state () {
        const accessToken = localStorage.getItem("accessToken") || "";
        return {
            accessToken,
            parsedAccessToken: accessToken ? jwtDecode(accessToken) : null,
            refreshToken: localStorage.getItem("refreshToken") || ""
        }
    },
    mutations: {
        SET_ACCESS_TOKEN(state, accessToken) {
            state.accessToken = accessToken;
            state.parsedAccessToken = accessToken ? jwtDecode(accessToken) : null;
            localStorage.setItem("accessToken", accessToken);
        },
        SET_REFRESH_TOKEN(state, refreshToken) {
            state.refreshToken = refreshToken;
            localStorage.setItem("refreshToken", refreshToken);
        }
    },
    getters: {
        loggedIn(state) {
            return state.accessToken && state.refreshToken && state.parsedAccessToken;
        },
        session(state) {
            return state.parsedAccessToken;
        },
        breadscrubPath(state) {
            const path = [];
            if(router.currentRoute.value.name == "Groups") return path;
            if(state.group.group) {
                path.push({
                    text: state.group.group.name,
                    link: {
                        name: "Group",
                        params: {
                            groupId: state.group.group.id
                        }
                    }
                });
            }
            if(["Group", "Commands"].includes(router.currentRoute.value.name)) return path;
            if(state.station.station) {
                path.push({
                    text: state.station.station.mqttTopic,
                    link: {
                        name: "Station",
                        params: {
                            stationId: state.station.station.id
                        }
                    }
                });
            }
            return path;
        }
    },
    actions: {
        async fetchAccessToken(context) {
            const {refreshToken, accessToken} = await ApiDataSource.createAccessToken(context.state.refreshToken);
            context.commit("SET_ACCESS_TOKEN", accessToken);
            context.commit("SET_REFRESH_TOKEN", refreshToken);
        },
        async authenticate(context, {email, password}) {
            const {refreshToken} = await ApiDataSource.createRefreshToken(email, password);
            context.commit("SET_REFRESH_TOKEN", refreshToken);
        },
        async logout(context) {
            context.commit("SET_REFRESH_TOKEN", "");
            router.push("/login");
            context.commit("SET_ACCESS_TOKEN", "");
        }
    },
    modules: {
        groups: groupsModule,
        group: groupModule,
        station: stationModule,
        availableCommands: availableCommandsModule,
        loggedUser: loggedUserModule,
        menu: menuModule,
        user: userModule,
        weatherWarnings: weatherWarningsModule
    }
});
