<template>
    <router-link
        :to="{name: 'Group', params: {groupId: id}}"
        class="block shadow-md rounded-md p-6 bg-white dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700"
    >
        <div>
            <div class="flex-1 text-xl border-b mb-4 border-gray-500">
                {{title}}
            </div>
            <div>
                {{stationsCount}}
                {{stationsCount > 1 ? "Stations" : "Station"}}
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: "GroupViewItem",
    props: {
        id: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        stationsCount: {
            type: Number,
            required: true
        }
    }
}
</script>
