import { ApiDataSource } from "../../apollo/apiDataSource";
import loadingModule from "./loading.module.js";

export default {
    namespaced: true,
    state() {
        return {
            user: null
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        }
    },
    actions: {
        async fetch(context) {
            context.dispatch("loading/start");
            await ApiDataSource.fetchAccessTokenIfExpired();
            const user = await ApiDataSource.getMyUser();
            context.commit("setUser", user);
            context.dispatch("loading/stop");
        }
    },
    modules: {
        loading: loadingModule
    }
}
