import { ApiDataSource } from "../../apollo/apiDataSource";
import loadingModule from "./loading.module.js";

export default {
    namespaced: true,
    state() {
        return {
            commands: []
        }
    },
    mutations: {
        setAvailableCommands(state, commands) {
            state.commands = commands;
        }
    },
    actions: {
        async fetch(context) {
            context.dispatch("loading/start");
            await ApiDataSource.fetchAccessTokenIfExpired();
            const commands = await ApiDataSource.getAvailableCommands();
            context.commit("setAvailableCommands", commands);
            context.dispatch("loading/stop");
        }
    },
    modules: {
        loading: loadingModule
    }
}
