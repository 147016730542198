import { ApiDataSource } from "../../apollo/apiDataSource";
import loadingModule from "./loading.module.js";

export default {
    namespaced: true,
    state() {
        return {
            groups: []
        }
    },
    mutations: {
        setGroups(state, groups) {
            state.groups = groups;
        }
    },
    actions: {
        async fetch(context) {
            context.dispatch("loading/start");
            await ApiDataSource.fetchAccessTokenIfExpired();
            const groups = (await ApiDataSource.getGroups()).sort((a,b) => {
                if ( a.name < b.name ){
                    return -1;
                }
                if ( a.name > b.name ){
                    return 1;
                }
                return 0;
            });
            context.commit("setGroups", groups);
            context.dispatch("loading/stop");
        }
    },
    modules: {
        loading: loadingModule
    }
}
