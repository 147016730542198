<template>
    <div class="dark:bg-gray-900 bg-gray-100 dark:text-gray-200 text-gray-700 transition-colors flex align-top font-sans h-full fixed top-0 left-0 right-0 bottom-0">
        <Header v-if="$store.getters.loggedIn"/>
        <div class="flex-1 overflow-auto h-full">
            <div class="min-h-full" :class="{'container mx-auto p-6': $route.name != 'Map'}">
                <HeaderBar v-if="$route.name != 'Map'"/>
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "./components/Header.vue";
import HeaderBar from "./components/HeaderBar.vue";

export default {
    components: { Header, HeaderBar },
    name: 'App'
}
</script>
