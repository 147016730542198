<template>
    <div>
        <div class="loading">
            <div class="loading-item h-5 mt-2 mb-8 w-96 rounded"></div>
            <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
                <div class="bg-white dark:bg-gray-700 rounded-md shadow-md p-6">
                    <div class="flex flex-col gap-4">
                        <div class="loading-item h-4 mb-4 w-48 rounded"></div>
                        <div class="loading-item h-3 w-64 rounded"></div>
                        <div class="loading-item h-3 w-64 rounded"></div>
                    </div>
                </div>
                <div class="bg-white dark:bg-gray-700 rounded-md shadow-md p-6">
                    <div class="flex flex-col gap-4">
                        <div class="loading-item h-4 mb-4 w-48 rounded"></div>
                        <div class="loading-item h-3 w-64 rounded"></div>
                        <div class="loading-item h-3 w-64 rounded"></div>
                    </div>
                </div>
                <div class="bg-white dark:bg-gray-700 rounded-md shadow-md p-6">
                    <div class="flex flex-col gap-4">
                        <div class="loading-item h-4 mb-4 w-48 rounded"></div>
                        <div class="loading-item h-3 w-64 rounded"></div>
                        <div class="loading-item h-3 w-64 rounded"></div>
                    </div>
                </div>
                <div class="bg-white dark:bg-gray-700 rounded-md shadow-md p-6">
                    <div class="flex flex-col gap-4">
                        <div class="loading-item h-4 mb-4 w-48 rounded"></div>
                        <div class="loading-item h-3 w-64 rounded"></div>
                        <div class="loading-item h-3 w-64 rounded"></div>
                    </div>
                </div>
                <div class="bg-white dark:bg-gray-700 rounded-md shadow-md p-6">
                    <div class="flex flex-col gap-4">
                        <div class="loading-item h-4 mb-4 w-48 rounded"></div>
                        <div class="loading-item h-3 w-64 rounded"></div>
                        <div class="loading-item h-3 w-64 rounded"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "GroupsViewLoading"
}
</script>

<style scoped>
    .loading .loading-item {
        @apply bg-gray-400 dark:bg-gray-500 max-w-full;
    }

    .loading {
        @apply animate-pulse;
    }
</style>
