<template>
    <LoginView/>
</template>

<script>
import LoginView from "../components/LoginView.vue";

export default {
    components: { LoginView },
    name: "RouterLoginView"
}
</script>
