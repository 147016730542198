<template>
    <div>
        <div class="loading dark:bg-gray-800 bg-white p-6 rounded-md shadow-md mx-auto lg:mx-0 max-w-lg">
            <div class="loading-item h-5 mt-2 mb-8 w-96 rounded"></div>
            <div class="loading-item h-4 mt-6 w-64 rounded"></div>
            <div class="loading-item h-3 mt-4 w-36 rounded"></div>
            <div class="loading-item h-4 mt-6 w-64 rounded"></div>
            <div class="loading-item h-3 mt-4 w-36 rounded"></div>
            <div class="loading-item h-4 mt-6 w-64 rounded"></div>
            <div class="loading-item h-3 mt-4 w-36 rounded"></div>
            <div class="loading-item h-4 mt-6 w-64 rounded"></div>
            <div class="loading-item h-3 mt-4 w-36 rounded"></div>
            <div class="loading-item h-4 mt-6 w-64 rounded"></div>
            <div class="loading-item h-3 mt-4 w-36 rounded"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AccountViewLoading"
}
</script>

<style scoped>
    .loading .loading-item {
        @apply bg-gray-400 dark:bg-gray-500 max-w-full;
    }

    .loading {
        @apply animate-pulse;
    }
</style>
