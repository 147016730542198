<template>
    <AccountViewLoading v-if="loading"/>
    <AccountView v-else/>
</template>

<script>
import AccountView from "../components/AccountView.vue";
import AccountViewLoading from "../components/AccountViewLoading.vue";
import {store} from "../store/store.js";

export default {
    components: { AccountView, AccountViewLoading },
    name: "RouterAccountView",
    computed: {
        loading() {
            if(this.$route.name == "Account") return this.$store.state.loggedUser.loading.loading;
            else if(this.$route.name == "UpdateUser") return this.$store.state.user.userLoading.loading;
            else return false;
        }
    },
    beforeRouteEnter(to, from, next) {
        if(to.name == "Account")
        {
            store.dispatch("loggedUser/fetch");
        }
        else if(to.name == "UpdateUser")
        {
            store.dispatch("user/fetchUser", to.params.userId);
        }

        next();
    }
}
</script>
