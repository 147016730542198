<template>
    <CommandsViewLoading v-if="$store.state.group.loading.loading || $store.state.availableCommands.loading.loading"/>
    <CommandsView v-else/>
</template>

<script>

import {store} from "../store/store.js";
import CommandsView from "../components/CommandsView.vue";
import CommandsViewLoading from "../components/CommandsViewLoading.vue";

export default {
    name: "RouterCommandsView",
    components: {CommandsView, CommandsViewLoading},
    beforeRouteEnter(to, from, next) {
        next();
        store.dispatch("group/fetchWithInstallations", to.params.groupId);
        store.dispatch("availableCommands/fetch");
    }
}
</script>
