import { createRouter, createWebHistory } from 'vue-router';

import {store} from "../store/store.js";

import StationView from "../view/StationView.vue";
import GroupsView from "../view/GroupsView.vue";
import StationsView from "../view/StationsView.vue";
import CommandsView from "../view/CommandsView.vue";
import LoginView from "../view/LoginView.vue";
import MapView from "../view/MapView.vue";
import AccountView from "../view/AccountView.vue";
import UsersView from "../view/UsersView.vue";

const authRequiredRoutes = [
    "Account",
    "Users",
    "UpdateUser",
    "CreateUser",
    "Groups",
    "Group",
    "Commands",
    "Station",
    "Map"
];

const adminRoutes = [
    "Users",
    "UpdateUser",
    "CreateUser"
]

const routes = [
    {
        path: "/",
        redirect: "/map",
    },
    {
        path: "/account",
        name: "Account",
        component: AccountView
    },
    {
        path: "/users",
        name: "Users",
        component: UsersView
    },
    {
        path: "/users/new",
        name: "CreateUser",
        component: AccountView
    },
    {
        path: "/users/edit/:userId",
        name: "UpdateUser",
        component: AccountView
    },
    {
        path: "/map",
        name: "Map",
        component: MapView
    },
    {
        path: "/login",
        name: "Login",
        component: LoginView
    },
    {
        path: "/groups",
        name: "Groups",
        component: GroupsView,
    },
    {
        path: "/group/:groupId",
        name: "Group",
        component: StationsView,
    },
    {
        path: "/group/:groupId/commands",
        name: "Commands",
        component: CommandsView,
    },
    {
        path: "/station/:stationId",
        name: "Station",
        component: StationView,
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    store.dispatch("menu/close");
    if(store.getters.loggedIn && to.name == "Login")
    {
        return next("/");
    }
    if(authRequiredRoutes.includes(to.name) && !store.getters.loggedIn || adminRoutes.includes(to.name) && !store.state.parsedAccessToken.isAdmin)
    {
        return next("/login");
    }
    next();
});

export default router;
