<template>
    <div class="my-12 max-w-lg mx-auto p-6 shadow-md rounded-md bg-white dark:bg-gray-800">
        <h2 class="text-2xl mb-6">Heureux de vous revoir !</h2>
        <form @submit.prevent="login">
            <div class="flex flex-col gap-8" :class="{error}">
                <div>
                    <label for="email">Email</label>
                    <input v-model="email" type="email" name="email" placeholder="example@test.com">
                    <div v-if="error" class="error-msg">Nom d'utilisateur ou mot de passe invalide</div>
                </div>
                <div>
                    <label for="password">Mot de passe</label>
                    <input v-model="password" type="password" name="password" placeholder="secret">
                </div>
            </div>
            <input class="mt-8 mx-auto" type="submit" value="Se connecter">
        </form>
    </div>
</template>

<script>
export default {
    name: "LoginView",
    data() {
        return {
            email: "",
            password: "",
            error: false
        }
    },
    methods: {
        async login() {
            try
            {
                await this.$store.dispatch("authenticate", {
                    email: this.email,
                    password: this.password
                });
                await this.$store.dispatch("fetchAccessToken");
                this.error = false;
                this.$router.push("/");
            }
            catch
            {
                this.error = true;
            }
        }
    }
}
</script>
