<template>
<div>
    <button
        @click="openMenu"
        v-show="!stationOpen"
        class="menu-button lg:hidden block rounded-full p-4 hover:bg-gray-100 dark:hover:bg-gray-700
                bg-white dark:bg-gray-800 shadow-lg"
    >
        <icon type="mdi" :path="icons.mdiMenu"/>
    </button>
    <div class="flex relative">
        <div class="flex-1 h-screen z-10">
            <div id="map-view" class="w-full h-screen"></div>
        </div>
        <div v-if="stationOpen" class="p-4 h-screen w-full lg:max-w-sm xl:max-w-xl relative overflow-y-auto bg-white dark:bg-gray-800 z-20">
            <button @click="closeStation">
                <icon type="mdi" :path="icons.mdiWindowClose"/>
            </button>
            <StationView v-if="!$store.state.station.loading.loading" :small="true"/>
            <div v-else class="mt-48 mx-auto animate-spin w-max">
                <icon type="mdi" :size="48" :path="icons.mdiLoading"/>
            </div>
        </div>
        <div v-if="groupOpen" class="p-4 h-screen w-full lg:max-w-sm xl:max-w-xl relative overflow-y-auto bg-white dark:bg-gray-800 z-30">
            <button @click="closeGroup">
                <icon type="mdi" :path="icons.mdiWindowClose"/>
            </button>
            <CommandsView v-if="!$store.state.group.loading.loading && !$store.state.availableCommands.loading.loading"/>
            <div v-else class="mt-48 mx-auto animate-spin w-max">
                <icon type="mdi" :size="48" :path="icons.mdiLoading"/>
            </div>
        </div>
        <div class="hidden lg:block absolute top-2 left-16 z-10 rounded shadow-lg  bg-white dark:bg-gray-900  dark:text-gray-200 text-gray-700 p-4">
            {{ clock }}
        </div>
        <div v-if="$store.state.weatherWarnings.warnings.length > 0 && !weatherWarningsClosed" class="warnings lg:flex hidden flex-col gap-4 absolute top-28 left-4 z-10 h-96 overflow-y-auto rounded shadow-lg  bg-white dark:bg-gray-900  dark:text-gray-200 text-gray-700 p-4">
            <button @click="weatherWarningsClosed = true">
                <icon type="mdi" :path="icons.mdiWindowClose"/>
            </button>
            <div class="border border-blue-200 rounded p-2" v-for="warning in $store.state.weatherWarnings.warnings">
                <div class="font-bold">{{warning.title}}</div>
                <div>{{warning.message}}</div>
                <div>Zone : {{(this.$store.state.groups.groups.find(({id}) => warning.group == id)|| {name:"Inconnue"}).name}}</div>
                <div>Commande : {{warning.availableCommandOption.availableCommand.title}}</div>
                <div>Valeur : {{warning.availableCommandOption.title}}</div>
                <div>Date d'émission : {{new Date(warning.emitDate).toLocaleDateString("fr-CH")}} {{new Date(warning.emitDate).toLocaleTimeString("fr-CH")}}</div>
                <div>Date de fin : {{new Date(warning.endDate).toLocaleDateString("fr-CH")}} {{new Date(warning.endDate).toLocaleTimeString("fr-CH")}}</div>
            </div>
        </div>
        <button
            v-if="$store.state.weatherWarnings.warnings.length > 0 && weatherWarningsClosed"
            class="warnings lg:block hidden absolute top-28 left-4 z-10 rounded-full shadow-lg  bg-white dark:bg-gray-900  dark:text-gray-200 text-gray-700 p-4"
            @click="weatherWarningsClosed = false"
        >
            <icon type="mdi" :path="icons.mdiWeatherCloudyAlert"/>
        </button>
        <div class="hidden lg:block absolute bottom-4 left-4 z-10 rounded shadow-lg bg-white dark:bg-gray-900  dark:text-gray-200 text-gray-700 p-4">
            <div class="mb-2">Légende</div>
            <div class="flex items-center gap-4">
                <div class="map-marker-off w-4 h-4 shadow-none"></div>
                <div class="flex-1">Feu éteint</div>
            </div>
            <div class="flex items-center gap-4">
                <div class="map-marker-high-wind w-4 h-4 shadow-none"></div>
                <div class="flex-1">Vent fort</div>
            </div>
            <div class="flex items-center gap-4">
                <div class="map-marker-storm w-4 h-4 shadow-none"></div>
                <div class="flex-1">Tempête</div>
            </div>
            <div class="flex items-center gap-4">
                <div class="map-marker-offline w-4 h-4 shadow-none"></div>
                <div class="flex-1">Indisponible</div>
            </div>
            <div>
                <hr class="mt-2 mb-2">
            </div>
            <div class="flex items-center gap-4">
                <div class="map-marker-warning w-4 h-4 shadow-none static">!</div>
                <div class="flex-1">Défaut mineur</div>
            </div>
            <div class="flex items-center gap-4">
                <div class="map-marker-error w-4 h-4 shadow-none static">!</div>
                <div class="flex-1">Défaut majeur</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import L from "leaflet";
import * as icons from "@mdi/js";
import {ApiDataSource} from "../apollo/apiDataSource.js";
import "leaflet/dist/leaflet.css";

import StationView from "./StationView.vue";
import CommandsView from "./CommandsView.vue";

export default {
    name: "MapView",
    components: {
        StationView,
        CommandsView
    },
    data() {
        return {
            icons,
            map: null,
            mapLayer: null,
            stationOpen: false,
            weatherWarningsClosed: false,
            groupOpen: false,
            markers: {},
            polygons: {},
            clock: "",
            clockInterval: 0,
            updateInterval: 0
        }
    },
    methods: {
        async fetchMap() {
            const iconOff = 'map-marker-off';
            const iconStorm = 'map-marker-storm';
            const iconHighWind = 'map-marker-high-wind';
            const iconOffline = 'map-marker-offline';
            const iconWarning = '<div class="map-marker-warning">!<div>'
            const iconError = '<div class="map-marker-error">!<div>'

            this.$store.dispatch("groups/fetch");
            this.$store.dispatch("weatherWarnings/fetch");

            await ApiDataSource.fetchAccessTokenIfExpired();
            const groups = await Promise.all((await ApiDataSource.getGroups()).map(group => ApiDataSource.getGroup(group.id)))
            const stations = await Promise.all(
                await Promise.all(
                    groups.flatMap(group => group.stations
                        .map((s) => s.id)
                        .map(async (stationId) => ({
                            ...(await ApiDataSource.getStation(stationId)),
                            commands: await ApiDataSource.getRunningCommands(stationId),
                            activeEvents: await ApiDataSource.getActiveEvents(stationId)
                        }))
                    )
                )
            )
            const stationsGroupedByGroup = stations.reduce((prev, curr) => {
                if(!prev[curr.stationGroupId]) {
                    prev[curr.stationGroupId] = [];
                }
                prev[curr.stationGroupId].push(curr);
                return prev
            }, {});
            groups
                .map((group) => ({
                    groupId: group.id,
                    name: group.name,
                    polygon: group.polygon.split(/\s+/g).map((latlng) => latlng.split(",").map(Number))
                }))
                .forEach((group) => {
                    const groupCommand = stationsGroupedByGroup[group.groupId].reduce((carry, station) => {
                        if(station.commands.length <= 0) return carry;
                        const {value, endDate} = station.commands[0]
                        if(value > carry.value) {
                            carry.value = value
                        }

                        if(!carry.endDate || new Date(endDate).getTime() < carry.endDate.getTime()) {
                            carry.endDate = new Date(endDate)
                        }
                        
                        return carry
                    }, {value: 0, endDate: null})
                    //if(groupCommand.value > 0) {
                        if(!this.polygons[group.groupId]) {
                            this.polygons[group.groupId] = {
                                polygon: L.polygon(group.polygon,{}).on("click", () => {
                                        if(this.$store.state.parsedAccessToken.isOperator) this.showGroupCommand(group.groupId)
                                    })
                                    .bindTooltip("",{
                                        permanent: true,
                                        direction:"center",
                                        opacity: 0.7
                                    })
                                    .openTooltip()
                                    .addTo(this.map),
                                endDate: null,
                                group: group
                            }
                        }
                        let polygonColor = "white";
                        if(groupCommand.value == 90) {
                            polygonColor = "red";
                        } else if(groupCommand.value == 40) {
                            polygonColor = "yellow";
                        }

                        this.polygons[group.groupId].endDate = groupCommand.endDate
                        this.polygons[group.groupId].polygon
                            .setStyle({color: polygonColor, opacity: 0.5})
                        //this.polygons[group.groupId] = L.polygon(group.polygon, {color: (groupCommand.value == 90 ? 'red' : 'yellow')}).addTo(this.map)
                    //} else if(this.polygons[group.groupId]) {
                    //    this.polygons[group.groupId].polygon.unbindTooltip()
                    //    this.polygons[group.groupId].polygon.remove()
                    //    delete this.polygons[group.groupId]
                    //}
                })
            //var polygon = L.polygon(latlngs, {color: 'red'}).addTo(this.map).on("click", () => {
            //    alert("colorado")
            //});
            

            stations.forEach((s) => {
                if(this.$route.query.station == s.id)
                {
                    this.showStation(s.id);
                }
                let iconLight = iconOff
                if(!this.stationOnline(s.lastUpdate)) {
                    iconLight = iconOffline
                } else if(this.stationStorm(s)) {
                    iconLight = iconStorm
                } else if(this.stationHighWind(s)) {
                    iconLight = iconHighWind
                }
                let iconWarningHtml = ""
                if(this.stationError(s)) {
                    iconWarningHtml = iconError
                } else if(this.stationWarning(s)){
                    iconWarningHtml = iconWarning
                }
                const icon = L.divIcon({
                    className: iconLight,
                    html: iconWarningHtml,
                    iconSize: [24,24]
                })
                if(!this.markers[s.id]) {
                    const marker = L.marker([s.gpslat, s.gpslong], {icon})
                    this.markers[s.id] = marker
                    marker.addTo(this.map).on("click", () => {
                        this.showStation(s.id);
                    });
                    return
                }
                this.markers[s.id].setIcon(icon)
                this.markers[s.id].setLatLng([s.gpslat, s.gpslong])
            });
        },
        updateClock() {
            const currDate = new Date(Date.now())
            this.clock = `${currDate.toLocaleDateString("fr-CH")} ${currDate.toLocaleTimeString("fr-CH")}`

            Object.values(this.polygons).forEach(({polygon, group, endDate}) => {
                let tooltipContent = group.name;
                if(endDate) {
                    let diffTime = (endDate - Date.now()) / 1000;
                    if(diffTime < 0) diffTime = 0;
                    const hours = Math.floor(diffTime / 3600).toString().padStart(2,"0"); // 1h
                    diffTime -= hours * 3600;
                    const minutes = Math.floor(diffTime / 60).toString().padStart(2,"0"); // 1min
                    diffTime -= minutes * 60;
                    const seconds = Math.floor(diffTime).toString().padStart(2,"0");
                    tooltipContent += `<br>Temps de commande restant : ${hours}:${minutes}:${seconds}`
                }
                
                polygon.setTooltipContent(tooltipContent)
            })
        },
        openMenu() {
            if(!this.$store.state.menu.open)
            {
                // Prevent click outside from closing menu
                setTimeout(() => {
                    this.$store.dispatch("menu/open");
                }, 50);
            }
        },
        showStation(stationId) {
            this.stationOpen = true;
            this.groupOpen = false;
            this.$store.dispatch("station/fetch", stationId);
        },
        showGroupCommand(groupId) {
            this.groupOpen = true;
            this.stationOpen = false;
            this.$store.dispatch("group/fetchWithInstallations", groupId);
            this.$store.dispatch("availableCommands/fetch");
        },
        closeStation() {
            this.stationOpen = false;
            this.mapLayer.redraw();
        },
        closeGroup() {
            this.groupOpen = false;
            this.mapLayer.redraw();
        },
        stationOnline(lastUpdate) {
            return (new Date(lastUpdate).getTime() > Date.now() - 1800000); // 30min
        },
        stationWarning(station) {
            return (station.activeEvents || [])
                .some((ae) => ae.category == 1)
        },
        stationError(station) {
            return (station.activeEvents || [])
            .some((ae) => ae.category == 2)
        },
        stationStorm(station) {
            return (station.equipmentInstallations || [])
                .filter((ei) => ei.equipment.equipmentIdentifier == 2)
                .flatMap((ei) => ei.sensorData.filter((sd) => sd.type == "1"))
                .map((sd) => sd.value)
                .some((v) => v == 90)
        },
        stationHighWind(station) {
            return (station.equipmentInstallations || [])
                .filter((ei) => ei.equipment.equipmentIdentifier == 2)
                .flatMap((ei) => ei.sensorData.filter((sd) => sd.type == "1"))
                .map((sd) => sd.value)
                .some((v) => v == 40)
        }
    },
    mounted() {
        this.map = L.map('map-view').setView([46.6250247,6.7453059], 10);
        this.mapLayer = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            id: 'mapbox/streets-v11',
            tileSize: 256
        }).addTo(this.map);
        this.fetchMap()
        this.updateInterval = setInterval(() => {
            this.fetchMap()
        }, 15000)
        this.updateClock()
        this.clockInterval = setInterval(() => {
            this.updateClock()
        }, 500)
    },
    unmounted() {
        clearInterval(this.updateInterval)
        clearInterval(this.clockInterval)
    }
}
</script>
<style>
.warnings {
    max-width: 440px;
}
.menu-button {
    @apply fixed top-6 right-6;
    z-index: 99999999 !important;
}
.map-marker-off {
    @apply rounded-full shadow-md border-2 border-gray-500 bg-white;
}
.map-marker-high-wind {
    @apply rounded-full shadow-md border-2 border-white bg-yellow-300;
}
.map-marker-storm {
    @apply rounded-full shadow-md border-2 border-white bg-red-400;
}
.map-marker-offline {
    @apply rounded-full shadow-md border-2 border-white bg-gray-500;
}
.map-marker-warning {
    @apply relative bottom-1/2 left-1/2 rounded-full shadow-md bg-yellow-500 text-white w-5 h-5 text-center;
    line-height: 16px;
}
.map-marker-error {
    @apply relative bottom-1/2 left-1/2 rounded-full shadow-md bg-red-700 text-white w-5 h-5 text-center;
    line-height: 16px;
}

</style>
