<template>
    <UsersViewLoading v-if="$store.state.user.loading.loading"/>
    <UsersView v-else/>
</template>

<script>
import UsersView from "../components/UsersView.vue";
import UsersViewLoading from "../components/UsersViewLoading.vue";
import {store} from "../store/store.js";

export default {
    components: { UsersView, UsersViewLoading },
    name: "RouterUsersView",
    beforeRouteEnter(to, from, next) {
        next();
        store.dispatch("user/fetchAll").then(() => {
            console.log(store.state.user.users);
        });
    }
}
</script>
