<template>
        <div class="dark:bg-gray-800 bg-white p-6 rounded-md shadow-md">
            <div class="loading">
                <div class="loading-item h-6 mb-8 w-96 rounded"></div>
                <div class="flex gap-4 items-center">
                    <div class="loading-item h-4 w-32 rounded"></div>
                    <div class="loading-item h-8 w-64 rounded-full"></div>
                </div>
                <div class="loading-item h-8 w-36 mt-8 rounded-full"></div>
            </div>
        </div>
</template>

<script>
export default {
    name: "CommandsViewLoading"
}
</script>

<style scoped>
    .loading .loading-item {
        @apply bg-gray-400 dark:bg-gray-500 max-w-full;
    }

    .loading {
        @apply animate-pulse;
    }
</style>
