<template>
    <div class="container p-6 mx-auto">
        <div class="dark:bg-gray-800 bg-white p-6 rounded-md shadow-md">
            <div class="loading">
                <div class="flex mb-8 items-center">
                    <div class="loading-item h-6 w-48 rounded"></div>
                    <div class="ml-auto loading-item h-8 w-24 rounded-full"></div>
                </div>
                <div class="loading-item h-6 w-full mt-16 rounded"></div>
                <div class="loading-item h-4 w-full mt-12 rounded"></div>
                <div class="loading-item h-4 w-full mt-8 rounded"></div>
                <div class="loading-item h-4 w-full mt-8 rounded"></div>
                <div class="loading-item h-4 w-full mt-8 rounded"></div>
                <div class="loading-item h-4 w-full mt-8 rounded"></div>
                <div class="loading-item h-4 w-full mt-8 rounded"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "UsersViewLoading"
}
</script>

<style scoped>
    .loading .loading-item {
        @apply bg-gray-400 dark:bg-gray-500 max-w-full;
    }

    .loading {
        @apply animate-pulse;
    }
</style>
