import { ApiDataSource } from "../../apollo/apiDataSource";
import loadingModule from "./loading.module.js";

export default {
    namespaced: true,
    state() {
        return {
            warnings: []
        }
    },
    mutations: {
        setWarnings(state, warnings) {
            state.warnings = warnings;
        }
    },
    actions: {
        async fetch(context) {
            context.dispatch("loading/start");
            await ApiDataSource.fetchAccessTokenIfExpired();
            const warnings = await ApiDataSource.getWeatherWarnings();
            context.commit("setWarnings", warnings);
            context.dispatch("loading/stop");
        }
    },
    modules: {
        loading: loadingModule
    }
}
